<template>
  <div
    class="w-72 shrink-0 h-full flex flex-col justify-start border-r border-gray-200"
  >
    <HomeTopbar />

    <div v-if="workspace?.id" class="px-m pb-l pt-m w-full h-full">
      <WorkspaceDropdown navigate />
      <UVerticalNavigation :links="sidebarItems" class="mt-m">
        <template #badge="{ link }">
          <div v-if="link.badge" class="contents">
            <UTooltip
              v-if="link.type === 'create'"
              class="ml-auto z-50"
              text="Create a Team"
              :popper="{ placement: 'right' }"
            >
              <UIcon name="i-ph-plus" />
            </UTooltip>

            <UDropdown
              v-else-if="link.type === 'edit'"
              class="ml-auto z-50"
              :items="teamActions(link)"
              :popper="{ placement: 'bottom-start' }"
            >
              <UButton
                :padded="false"
                color="gray"
                variant="link"
                icon="i-ph-dots-three"
              />
            </UDropdown>
            <UBadge
              v-else
              color="primary"
              class="z-50 ml-auto"
              size="sm"
              :ui="{
                base: 'flex',
                rounded: 'rounded-full',
                size: { sm: 'w-5 h-5' },
              }"
            >
              {{ link.badge }}
            </UBadge>
          </div>
        </template>
      </UVerticalNavigation>
    </div>
    <div class="mt-auto px-m mb-m w-full">
      <SpellRunMeter v-if="workspaceId" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Team } from '@respell/database';
import { TeamType } from '@respell/database';
import WorkspaceDropdown from '~/components/account/WorkspaceDropdown.vue';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import CreateMemberModal from '~/components/modals/CreateMemberModal.vue';
import CreateTeamModal from '~/components/modals/CreateTeamModal.vue';
import SpellRunMeter from '~/components/subscription/SpellRunMeter.vue';
import { useWorkspaceStore } from '~/stores/workspaces';

const workspaceStore = useWorkspaceStore();
const { personalTeam, workspace, workspaceId, workspaceTeam, workspaceTeams } =
  storeToRefs(workspaceStore);

const modal = useModal();

const openCreateTeamModal = () => {
  modal.open(CreateTeamModal);
};

const openCreateMemberModal = async (team: Team) => {
  await navigateTo(`/teams/${team.id}/spells`);
  modal.open(CreateMemberModal);
};

const openDeleteTeamModal = (team: Team) => {
  modal.open(ConfirmModal, {
    action: 'delete',
    type: team?.label || 'Team',
    message: 'Are you sure you want to delete this team?',
    isDangerous: true,
    async onConfirm() {
      await workspaceStore.deleteTeam(team.id);
      modal.close();
    },
  });
};

const { routeName } = useRouteName();
const teamParam = useRouteParams('teamId');

const sidebarItems = computed(() => {
  const baseItems = [
    [
      {
        label: 'Home',
        to: '/',
        icon:
          routeName.value === 'dashboard' ? 'i-ph-house-fill' : 'i-ph-house',
      },
      {
        label: 'Templates',
        to: '/templates',
        icon:
          routeName.value === 'templates' ? 'i-ph-stack-fill' : 'i-ph-stack',
      },
      {
        label: 'Inbox',
        to: { name: 'inbox', params: { tabId: 'todo' } },
        icon: routeName.value === 'inbox' ? 'i-ph-tray-fill' : 'i-ph-tray',
        active: routeName.value === 'inbox',
        badge: workspaceStore.todoCount,
      },
    ],
    [
      {
        label: personalTeam.value?.name || 'My Spells',
        to: `/teams/${personalTeam.value?.id}/spells`,
        icon:
          teamParam.value === personalTeam.value?.id
            ? 'i-ph-magic-wand-fill'
            : 'i-ph-magic-wand',
      },
      {
        label: workspaceTeam.value?.name || 'Workspace Spells',
        to: `/teams/${workspaceTeam.value?.id}/spells`,
        icon:
          teamParam.value === workspaceTeam.value?.id
            ? 'i-ph-users-three-fill'
            : 'i-ph-users-three',
      },
      {
        label: 'History',
        to: { name: 'history' },
        icon:
          routeName.value === 'history'
            ? 'i-ph-clock-counter-clockwise-bold'
            : 'i-ph-clock-counter-clockwise',
      },
      {
        label: 'Data Sources',
        to: '/data-sources',
        icon:
          routeName.value === 'dataSources'
            ? 'i-ph-database-fill'
            : 'i-ph-database',
      },
      {
        label: 'Agents',
        to: '/agents',
        icon: routeName.value === 'agents' ? 'i-ph-robot-bold' : 'i-ph-robot',
      },
    ],
  ];

  const teamsBase = [
    {
      label: 'Teams',
      type: 'create',
      badge: true,
      click: openCreateTeamModal,
    },
    ...workspaceTeams.value
      .filter((team) => team.type === TeamType.custom)
      .map((team) => {
        const isSelected = teamParam.value === team.id;

        return {
          id: team.id,
          label: team.name,
          badge: team.type === 'custom',
          type: 'edit',
          to: `/teams/${team.id}/spells`,
          avatar: {
            size: 'sm',
            alt: team.name?.[0],
            background: 'bg-red',
            ui: {
              rounded: 'rounded-lg',
              background: `${isSelected ? 'bg-primary-500' : 'bg-gray-100'}`,
              placeholder: `text-lg leading-none truncate ${isSelected ? 'font-bold text-white' : 'font-medium text-gray-500'}`,
            },
          },
        };
      }),
  ];

  baseItems.push(teamsBase);

  baseItems.push([
    {
      label: 'Help & Resources',
      to: 'https://docs.respell.ai',
      external: true,
      target: '_blank',
      icon: 'i-ph-question',
    },
  ]);

  // TODO: This feature needs to be updated in V2
  // Conditionally prepend the Verify your Email option if it should be visible
  // if (!user.value?.isEmailVerified) {
  //   baseItems[0].unshift({
  //     label: 'Verify your email',
  //     to: '/request-email-verification',
  //     icon: 'mail',
  //   });
  // }

  return baseItems;
});

const teamActions = (team: Team) => {
  return [
    [
      {
        label: 'Add Members',
        icon: 'i-ph-user-plus',
        click: () => openCreateMemberModal(team),
        // disabled: !can('addMember', subject('Team', team)),
      },
    ],
    [
      {
        label: 'Delete team',
        icon: 'i-ph-trash',
        click: () => openDeleteTeamModal(team),
        // disabled: !can('delete', subject('Team', team)),
      },
    ],
  ];
};
</script>
